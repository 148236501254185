import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Spinner } from "react-bootstrap";
import { usStates } from "./const/usStates";
import APICaller from "./services/ApiRequest";
import { SEARCH_LICENSE_PLATE_NUMBER } from "./const/endPoints";

function App() {
  const [data, setData] = useState();
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    APICaller(SEARCH_LICENSE_PLATE_NUMBER(data.plate_number, "PA"), "GET")
      .then((res) => {
        if (res && res.status === "ok" && res.content) setContent(res.content);
        if (res && res.status === "error" && res.message)
          setContent(res.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <div className="App">
      <div className="main-wrapper">
        <form onSubmit={handleSubmit} className="form-wrapper">
          <Form.Control
            type="text"
            name="plate_number"
            placeholder="Enter Plate Number e.g AF60214"
            onChange={(e) => onChange(e)}
            className="input-field"
            required
          />
          <Form.Select
            name="usState"
            onChange={(e) => onChange(e)}
            className="input-field"
            placeholder="Select State"
            required
          >
            {usStates.map((data, index) => {
              return <option key={index}>{data.label}</option>;
            })}
          </Form.Select>
          <Button type="submit" className="submit-button">
            {isLoading ? (
              <Spinner animation="border" className="custom-spinner-class" />
            ) : (
              "Search"
            )}
          </Button>
        </form>
        {content ? (
          <div className="content-wrapper">
            <div className="content-item">
              <div className="content-label">Country</div>
              <div className="content-value">{content?.country}</div>
            </div>
            <div className="content-item">
              <div className="content-label">Make</div>
              <div className="content-value">{content?.make}</div>
            </div>
            <div className="content-item">
              <div className="content-label">Model</div>
              <div className="content-value">{content?.model}</div>
            </div>
            <div className="content-item">
              <div className="content-label">State</div>
              <div className="content-value">{content?.state}</div>
            </div>
            <div className="content-item">
              <div className="content-label">Vin</div>
              <div className="content-value">{content?.vin}</div>
            </div>
            <div className="content-item">
              <div className="content-label">Year</div>
              <div className="content-value">{content?.year}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default App;
