import axios from "axios";

const HTTP = axios.create({
  baseURL: `https://corsanywhere.herokuapp.com/${process.env.REACT_APP_API_ENDPOINT}`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export default async function APICaller(endpoint) {
  try {
    const response = await HTTP.get(`${endpoint}`);
    return response.data;
  } catch (error) {
    return error;
  }
}
